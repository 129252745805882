<template>
  <div class="contents-section">
    <div class="title-section">
      <h2 class="d-flex align-start">
        리딩방관리
        <v-tooltip
          right
          color="black"
        >
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              class="mt-1 ml-1"
              v-bind="attrs"
              v-on="on"
              @click="getSummary()"
              :loading="loading"
              color="primary"
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>새로고침</span>
        </v-tooltip>
      </h2>
      <v-btn
        depressed
        color="primary"
        @click="init(); dialog.show = true;"
      >
        리딩방등록
      </v-btn>
    </div>
    <div class="main-section">
      <search-filter
        :radioFilters="radioFilters"
        :search="search"
        type="server"
        searchPlaceHolder="리딩방명으로 검색"
        @updateSearch="updateSearch"
        @updateRadio="updateRadio"
      />
      <v-row
        justify="space-between"
        align="center"
        class="mr-0 ml-0 mt-0 mb-0"
      >
        <v-col class="d-flex align-center pl-0">
          <v-radio-group
            @change="itemsPerPage = $event"
            dense
            row
            :value="itemsPerPage"
            hide-details
          >
            <v-radio
              small
              label="50개씩 보기"
              :value="50"
            ></v-radio>
            <v-radio
              small
              label="100개씩 보기"
              :value="100"
            ></v-radio>
            <v-radio
              small
              label="500개씩 보기"
              :value="500"
            ></v-radio>
            <v-radio
              small
              label="1000개씩 보기"
              :value="1000"
            ></v-radio>
            <v-radio
              small
              label="3000개씩 보기"
              :value="3000"
            ></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <data-table
        :items="lists"
        :sorts.sync="sorts"
        :headers="headers"
        itemKey="no"
        :loading="loading"
      >
        <template #name="{ item }">
          <v-menu
            offset-y
            :close-on-content-click="false"
          >
            <template #activator="{ on, attrs }">
              {{ item.name }}
              <v-btn
                small
                icon
                v-bind="attrs"
                v-on="on"
                @click="roomName = item.name"
              >
                <v-icon
                  small
                  color="primary"
                >mdi-pencil</v-icon>
              </v-btn>
            </template>
            <div class="text-input">
              <v-text-field
                :autofocus="true"
                solo
                :value="roomName"
                dense
                flat
                hide-details
                @input="roomName = $event"
                @keydown="if($event.key === 'Enter') {
                  updateRoom('name', item.no, roomName);
                }"
              ></v-text-field>
              <v-btn
                class="mt-1"
                color="primary"
                depressed
                dense
                small
                @click="updateRoom('name', item.no, roomName)"
              >수정</v-btn>
            </div>
          </v-menu>
        </template>
        <template #creator="{ item }">
          <div class="d-flex align-center justify-left">
            <v-chip
              link
              style="height: auto; white-space: pre-wrap;"
              class="pl-1 pr-1 mr-1"
              small
              label
              outlined
            >{{ comm.getGroupFullPath(groups, item.creatorGroupNo, '그룹없음') }}</v-chip>
            {{ item.creatorName }}
          </div>
        </template>
        <template #created="{ item }">
          {{ time.makeLocalTime(item.created, 'min') }}
        </template>
        <template #disabled="{ item }">
          <v-chip
            small
            link
            :color="item.disabled === true ? 'error' : 'success'"
            class="pr-2"
            @click="updateRoom('disabled', item.no, !item.disabled)"
          >
            {{ item.disabled === true ? '비활성' : '활성' }}
            <v-icon
              small
              class="ml-1"
            >mdi-swap-horizontal</v-icon>
          </v-chip>
        </template>
      </data-table>
      <div class="text-center pt-4 d-flex justify-center align-center">
        <v-btn
          icon
          v-if="Math.ceil(totalItems / itemsPerPage) > 1"
          @click="page = 1"
          :disabled="page === 1"
        >
          <v-icon>mdi-chevron-double-left</v-icon>
        </v-btn>
        <v-pagination
          v-if="itemsPerPage > 0"
          small
          color="primary"
          v-model="page"
          :length="Math.ceil(totalItems / itemsPerPage)"
          totalVisible="15"
          depressed
        ></v-pagination>
        <v-btn
          icon
          v-if="Math.ceil(totalItems / itemsPerPage) > 1"
          @click="page = Math.ceil(totalItems / itemsPerPage)"
          :disabled="page === Math.ceil(totalItems / itemsPerPage)"
        >
          <v-icon>mdi-chevron-double-right</v-icon>
        </v-btn>
      </div>
    </div>
    <v-dialog
      v-model="dialog.show"
      scrollable
      max-width="500"
      @click:outside="init"
    >
      <v-card>
        <v-card-title class="d-flex align-center justify-space-between pt-6">
          <span class="text-h5">리딩방등록</span>
        </v-card-title>
        <v-card-text class="pb-0 content-wrap">
          <div class="content-box">
            <div class="section-wrap">
              <div class="section">
                <div class="text-input-wrap mt-2">
                  <v-text-field
                    v-model="dialog.name"
                    outlined
                    dense
                    flat
                    label="리딩방명"
                    :rules="[
                      (dialog.name.length > 0 && dialog.name.length <= 20)|| '1~20자로 입력해주세요.'
                    ]"
                    autofocus
                  />
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
        <v-card-actions class="pb-6 pr-6 pt-0">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            depressed
            :disabled="!/^[a-zA-Z가-힣ㄱ-ㅎ0-9]{1,20}$/g.test(dialog.name)"
            @click="registRoom"
          >
            등록
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import SearchFilter from '@/components/layout/SearchFilter.vue';
import DataTable from '@/components/datatable/DataTable.vue';
import time from '@/util/time';
import comm from '@/util/comm';
import debounce from 'debounce';

export default {
  name: 'Rooms',
  components: {
    SearchFilter,
    DataTable,
  },
  data: () => ({
    comm,
    time,
    groups: [],
    loading: true,
    lists: [],
    search: '',
    totalItems: 0,
    radioFilters: {
      disabled: {
        key: 'disabled',
        name: '활성여부',
        value: 0,
        data: [
          { name: '전체', value: -1 },
          { name: '활성', value: 0 },
          { name: '비활성', value: 1 },
        ],
      },
    },
    page: 1,
    itemsPerPage: 50,
    sorts: {
      sortBy: ['no'],
      sortDesc: [true],
    },
    headers: [
      {
        text: '번호',
        value: 'no',
        align: 'center',
        width: 54,
        sortable: false,
      },
      {
        text: '리딩방명',
        value: 'name',
        align: 'center',
        sortable: true,
      },
      {
        text: '등록인',
        value: 'creator',
        align: 'center',
        width: 280,
        sortable: false,
      },
      {
        text: '등록일시',
        value: 'created',
        align: 'center',
        width: 180,
        sortable: true,
      },
      {
        text: '활성여부',
        value: 'disabled',
        align: 'center',
        width: 140,
        sortable: false,
      },
    ],
    roomName: '',
    dialog: {
      show: false,
      name: '',
    },
  }),
  computed: {
    ...mapGetters({
      userInfo: 'auth/userInfo',
      route: 'router/route',
    }),
  },
  methods: {
    ...mapMutations({
      progress: 'dialog/progress',
      confirm: 'dialog/confirm',
    }),
    ...mapActions({
      alert: 'dialog/alert',
    }),
    /* 검색 업데이트 */
    updateSearch(value) {
      this.search = value;
    },
    /* 라디오필터 업데이트 */
    updateRadio(value, key) {
      this.radioFilters[key].value = value;
    },
    makeOptions() {
      const optionsForm = {
        page: this.page,
        itemsPerPage: this.itemsPerPage,
        sortBy: this.sorts.sortBy,
        sortDesc: this.sorts.sortDesc,
        filters: [
          {
            condition: 'inc',
            column: 'name',
            value: this.search,
          },
        ],
      };
      const disabledRadioValue = this.radioFilters.disabled.value;
      if (disabledRadioValue !== -1) {
        optionsForm.filters.push({
          where: 'and',
          condition: 'eq',
          column: 'disabled',
          value: disabledRadioValue,
        });
      }
      return optionsForm;
    },
    getSummary(reset = true) {
      if (reset === true) this.lists = [];
      this.loading = true;
      const options = this.makeOptions();
      this.$socket.emit('rooms.summary.get', {
        filters: options.filters,
      }, (resp) => {
        if (resp.result === 'success') {
          this.$socket.emit('rooms.list.get', options, (response) => {
            if (response.result === 'success') {
              this.totalItems = resp.item.count;
              this.lists = Object.freeze(response.items);
              if (this.page > Math.ceil(this.totalItems / this.itemsPerPage)) {
                this.page = 1;
              }
              this.loading = false;
            } else {
              this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${response.code})`]);
              console.error(response);
              this.loading = false;
            }
          });
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          this.loading = false;
        }
      });
    },
    getGroupList() {
      this.$socket.emit('groups.list.get', {
        page: 1,
        itemsPerPage: 0,
        sortBy: ['no'],
        sortDesc: [false],
        filters: [],
      }, (resp) => {
        if (resp.result === 'success') {
          this.groups = resp.items.groups;
          this.getSummary();
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
        }
      });
    },
    updateRoom(command, target, value) {
      const msg = {
        name: '리딩방의 이름이 변경되었습니다.',
        disabled: '리딩방의 활성여부가 변경되었습니다.',
      };
      const val = command === 'name' ? value.trim() : value;
      if (command === 'name' && (this.roomName.trim().length > 20 || this.roomName.trim().length <= 0)) {
        this.alert(['error', '리딩방명은 1~20자로 작성해주세요.']);
      } else {
        const param = {
          no: target,
        };
        param[command] = val;
        this.$socket.emit('rooms.update', param, (resp) => {
          if (resp.result === 'success') {
            this.getSummary(false);
            this.alert(['success', msg[command]]);
          } else {
            this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
            console.error(resp);
            this.loading = false;
          }
        });
      }
    },
    registRoom() {
      const name = this.dialog.name != null ? this.dialog.name.trim() : '';
      if (name.length > 20 || name.length <= 0) {
        this.alert(['error', '리딩방명은 1~20자로 작성해주세요.']);
      } else {
        this.$socket.emit('rooms.add', {
          name,
        }, (resp) => {
          if (resp.result === 'success') {
            this.getSummary(false);
            this.alert(['success', '리딩방이 등록되었습니다.']);
            this.dialog.show = false;
          } else {
            this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
            console.error(resp);
            this.loading = false;
            this.dialog.show = false;
          }
        });
      }
    },
    init() {
      this.dialog.show = false;
      this.dialog.name = '';
    },
  },
  watch: {
    sorts: {
      deep: true,
      handler() {
        this.getSummary(false);
      },
    },
    radioFilters: {
      deep: true,
      handler() {
        this.getSummary();
      },
    },
    search: {
      handler() {
        this.getSummary();
      },
    },
    selectFilters: {
      deep: true,
      handler() {
        this.getSummary();
      },
    },
    itemsPerPage: {
      handler() {
        this.getSummary();
      },
    },
    page: {
      handler() {
        this.getSummary();
      },
    },
    userInfo: {
      immediate: true,
      handler(val) {
        if (val !== null) {
          this.getSummary = debounce(this.getSummary, 300);
          this.getGroupList();
        }
      },
    },
  },
};
</script>
<style scoped lang="scss">
.text-input {
  background-color: #fff;
  padding: 5px;
  padding-right: 8px;
  padding-top: 2px;
  width: 240px;
  display: flex;
  align-items: center;
}
</style>
